.teaserContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.starBackground {
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(white, rgba(255,255,255,.2) 2px, transparent 40px),
    radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 30px),
    radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 40px);
  background-size: 550px 550px, 350px 350px, 250px 250px;
  background-position: 0 0, 40px 60px, 130px 270px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.teaserContent {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  max-width: 80%;
}

.teaserContent h2 {
  font-size: 2rem;
  color: #FFD700;
  margin-bottom: 1rem;
}

.teaserContent p {
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .teaserContent h2 {
    font-size: 1.5rem;
  }

  .teaserContent p {
    font-size: 1rem;
  }
}