.homeScreen {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
}

.parallaxSection {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.content {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  max-width: 600px;
  color: #fff;
}

.content h1, .content h2 {
  font-size: 2.5rem;
  color: #FFD700;
  margin-bottom: 1rem;
}

.content p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.ctaButton, .exploreBtn {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background-color: #FFD700;
  color: #000;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.ctaButton:hover, .exploreBtn:hover {
  background-color: #FFA500;
  transform: translateY(-2px);
}

.scrollIndicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-align: center;
  font-size: 0.9rem;
  animation: bounce 2s infinite;
}

.teaserSection {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translate(-50%, 0); }
  40% { transform: translate(-50%, -10px); }
  60% { transform: translate(-50%, -5px); }
}

/* iOS-specific styles */
@supports (-webkit-touch-callout: none) {
  .parallaxSection {
    background-attachment: scroll; /* Disable fixed background on iOS */
  }
  .scrollIndicator {
    bottom: 6rem; /* Adjust this value as needed for iOS devices */
  }
}

@media (max-width: 768px) {
  .content {
    padding: 1rem;
    max-width: 90%;
  }

  .content h1, .content h2 {
    font-size: 1.8rem;
  }

  .content p {
    font-size: 1rem;
  }

  .ctaButton, .exploreBtn {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }

  .scrollIndicator {
    bottom: 4rem; /* Moved higher for mobile devices */
    font-size: 0.8rem;
  }

  .parallaxSection {
    background-size: cover; /* This ensures the image covers the entire section */
    background-position: center center;
  }

  .apeClub {
    background-position: center 20%; /* Adjust this value as needed */
  }

  .mooseClub {
    background-position: center 20%; /* Adjust this value as needed */
  }
}

@media (max-width: 480px) {
  .parallaxSection {
    background-size: cover; /* Maintain full coverage on smaller screens */
  }
  .scrollIndicator {
    bottom: 5rem; /* Even higher for very small screens */
  }
}

/* Ensure buttons are easily tappable on mobile */
@media (hover: none) {
  .ctaButton, .exploreBtn {
    padding: 1rem 1.5rem; /* Larger tap target */
  }
}