.topNav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .navLogo {
    height: 40px;
    width: auto;
  }
  
  .navLinks {
    display: flex;
    list-style-type: none;
  }
  
  .navLinks li {
    margin-left: 1rem;
  }
  
  .navLinks a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .navLinks a:hover {
    color: #FFD700;
  }
  
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .hamburger span {
    height: 2px;
    width: 25px;
    background: #fff;
    margin-bottom: 4px;
    transition: all 0.3s ease;
  }
  
  @media (max-width: 768px) {
    .hamburger {
      display: flex;
    }
  
    .navLinks {
      position: fixed;
      top: 60px;
      right: -100%;
      flex-direction: column;
      background-color: rgba(0, 0, 0, 0.9);
      width: 100%;
      text-align: center;
      transition: 0.3s;
      box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    }
  
    .navLinks.active {
      right: 0;
    }
  
    .navLinks li {
      margin: 2.5rem 0;
    }
  }