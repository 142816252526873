@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}